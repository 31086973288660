import React from 'react'

import './Content.css'

const Content = ({ source, src, className = '' }) => {
  source = source || src || ''
  return (
    <div
      className={`Content ${className}`}
      dangerouslySetInnerHTML={{ __html: source }}
    />
  )
}

export default Content
