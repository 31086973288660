import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'

import './Image.css'

const extractChildImageSharp = (src = '', format) => {
  if (!format) {
    if (_isString(src) && !format) {
      return src
    }

    const childImageSharp = _get(src, 'childImageSharp')
    if (!childImageSharp) return _get(src, 'publicURL')
    return childImageSharp
  }
  if (format === 'fluid' || format === 'fixed') return src
  return src
}

class Image extends React.Component {
  render() {
    let {
      background,
      backgroundSize = 'cover',
      className = '',
      src,
      srcSet,
      source,
      onClick,
      sizes,
      alt,
      style,
      imgStyle,
    } = this.props

    const imageSrc = extractChildImageSharp(src || source)

    if (background) {
      let style = {}

      if (_isString(imageSrc)) {
        style = { backgroundImage: `url(${imageSrc})`, backgroundSize }
      }

      return (
        <div className={`BackgroundImage absolute ${className}`} style={style}>
          {!style.backgroundImage && (
            <Image
              src={src}
              alt={alt}
              style={{
                position: 'absolute',
                width: 'auto',
                height: 'auto',
              }}
              imgStyle={{
                objectFit: backgroundSize,
              }}
            />
          )}
        </div>
      )
    }

    if (_isObject(imageSrc)) {
      return (
        <GatsbyImage
          {...imageSrc}
          className={`Image ${className}`}
          onClick={onClick}
          alt={alt}
          style={style}
          imgStyle={imgStyle}
        />
      )
    }

    return (
      <img
        className={`Image ${className}`}
        src={imageSrc}
        sizes={sizes || '100vw'}
        onClick={onClick}
        alt={alt}
      />
    )
  }
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
}

export default Image

export const query = graphql`
  fragment FluidImage on File {
    publicURL
    childImageSharp {
      fluid(quality: 75) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment NoBlurImage on File {
    publicURL
    childImageSharp {
      fluid(quality: 75) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
  fragment TracedImage on File {
    publicURL
    childImageSharp {
      fluid(quality: 75) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment TracedImageMedium on File {
    publicURL
    childImageSharp {
      fluid(maxWidth: 800, quality: 75) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment TracedImageMediumSquare on File {
    publicURL
    childImageSharp {
      fluid(maxWidth: 800, maxHeight: 800, quality: 75) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment TracedImageSmall on File {
    publicURL
    childImageSharp {
      fluid(maxWidth: 400, quality: 75) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment LargeImage on File {
    publicURL
    childImageSharp {
      fluid(maxWidth: 1800, quality: 75) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment MediumImage on File {
    publicURL
    childImageSharp {
      fluid(maxWidth: 800, quality: 75) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment SmallImage on File {
    publicURL
    childImageSharp {
      fluid(maxWidth: 400, quality: 75) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment LargeImageFixed on File {
    publicURL
    childImageSharp {
      fixed(width: 1800, quality: 75) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  fragment MediumImageFixed on File {
    publicURL
    childImageSharp {
      fixed(width: 800, quality: 75) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  fragment MediumImageSquare on File {
    publicURL
    childImageSharp {
      fixed(width: 800, height: 800, quality: 75) {
        ...GatsbyImageSharpFixed_withWebp_tracedSVG
      }
    }
  }
  fragment SmallImageSquare on File {
    publicURL
    childImageSharp {
      fixed(width: 400, height: 400, quality: 75) {
        ...GatsbyImageSharpFixed_withWebp_tracedSVG
      }
    }
  }
  fragment SmallImageFixed on File {
    publicURL
    childImageSharp {
      fixed(width: 400, quality: 75) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`
